import React from "react"

import "@fontsource-variable/noto-sans/wdth.css"
import "@fontsource-variable/dosis/wght.css"
import "./src/normalize.css"
import { CookieConsentProvider } from "./src/context/CookieConsentContext"
import CookieConsentBanner from "./src/components/cookieConsentBanner"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <CookieConsentProvider>
        <CookieConsentBanner />
        {element}
      </CookieConsentProvider>
    </>
  )
}
