import React, { createContext, useState, useEffect, useContext } from "react"
import Cookies from "js-cookie"
import { consentCookieName, guessLanguage } from "../utils"

const CookieConsentContext = createContext()

const injectGTM = () => {
  const script = document.createElement("script")
  script.innerHTML =
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
    "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
    "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
    "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
    "})(window,document,'script','dataLayer','GTM-WQN6WX63');"
  document.head.appendChild(script)

  const noscript = document.createElement("noscript")
  noscript.innerHTML =
    '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQN6WX63"\n' +
    'height="0" width="0" style="display:none;visibility:hidden"></iframe>'
  document.body.appendChild(noscript)
}

export const CookieConsentProvider = ({ children }) => {
  const [showBanner, setShowBanner] = useState(false)
  const [hideBanner, setHideBanner] = useState(false)
  const location = window.location
  const cookieName = consentCookieName

  useEffect(() => {
    const consent = Cookies.get(cookieName)
    if (!consent) {
      setShowBanner(true)
    } else if (consent === "accepted") {
      injectGTM()
    }
  }, [cookieName])

  const handleAccept = () => {
    Cookies.set(cookieName, "accepted", { expires: 365 })
    setHideBanner(true)
    setTimeout(() => {
      setShowBanner(false)
      injectGTM()
    }, 500) // Match the animation duration
  }

  const handleDecline = () => {
    Cookies.set(cookieName, "declined", { expires: 365 })
    setHideBanner(true)
    setTimeout(() => {
      setShowBanner(false)
    }, 500) // Match the animation duration
  }

  const language = guessLanguage({ location })

  return (
    <CookieConsentContext.Provider
      value={{
        showBanner,
        hideBanner,
        handleAccept,
        handleDecline,
        location,
        language,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  )
}

export const useCookieConsent = () => useContext(CookieConsentContext)
