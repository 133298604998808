module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://prinonkhandaker.com","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","fr"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/","getLanguageFromPath":true},{"matchPath":"/:lang/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false,"showCaptions":true,"withWebp":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Prinon Khandaker | Wisdom of a Real Estate Investor","short_name":"Prinon Khandaker","description":"Discover expert insights, tips, and strategies from the Prinon Khandaker on real estate investment in Montreal and beyond. Stay updated with the latest market trends, property analysis, and investment opportunities from a truly seasoned real estate investor and entrepreneur.","lang":"en","display":"minimal-ui","start_url":"/","background_color":"#ffffff","theme_color":"rgb(86, 111, 163)","icon":"src/images/pk-logo.png","localize":[{"start_url":"/fr/","lang":"fr","name":"Prinon Khandaker | Sagesse d'un investisseur immobilier","short_name":"Prinon Khandaker","description":"Discover expert insights, tips, and strategies from the Prinon Khandaker on real estate investment in Montreal and beyond. Stay updated with the latest market trends, property analysis, and investment opportunities from a truly seasoned real estate investor and entrepreneur."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b0efa632d0bc90ea16fdbc96e640afe5"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
