const calculateReadingTime = text => {
  const wordsPerMinute = 200
  const wordCount = text.split(/\s/g).length
  return Math.ceil(wordCount / wordsPerMinute)
}

const convertAllSlugsToAlternatePages = allSlugs => {
  return Object.entries(allSlugs).map(([language, slug]) => ({
    hrefLang: language,
    href: `${slug}`,
  }))
}
const guessLanguage = ({ location }) => {
  const path = location.pathname
  if (path.startsWith("/fr/")) {
    return "fr"
  }
  return "en"
}
const consentCookieName = "_pkcc"
export {
  calculateReadingTime,
  convertAllSlugsToAlternatePages,
  consentCookieName,
  guessLanguage,
}
