import { graphql, useStaticQuery } from "gatsby"
export const useStaticTranslations = ({ language }) => {
  const data = useStaticQuery(graphql`
    query {
      allLocale {
        edges {
          node {
            data
            language
          }
        }
      }
    }
  `)

  const translations = {}
  data.allLocale.edges
    .filter(({ node }) => node.language === language)
    .forEach(({ node }) => {
      Object.assign(translations, JSON.parse(node.data))
    })

  return translations
}
