import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { Link } from "gatsby-plugin-react-i18next"
import { useCookieConsent } from "../context/CookieConsentContext"
import { useStaticTranslations } from "../hooks/useStaticTranslations"

const riseUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const fallDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`

const CookieConsentBannerWrapper = styled.div`
  position: fixed;
  font-family: "Dosis Variable", sans-serif;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  text-align: center;
  padding: 1em;
  z-index: 1000;
  animation: ${riseUp} 0.5s ease-out forwards;
  &.fallDown {
    animation: ${fallDown} 0.5s ease-out forwards;
  }
`

const CookieConsentText = styled.div`
  margin: 0;
  padding: 0;
  display: inline;
`

const CookieConsentTitle = styled.h2`
  margin-top: 0;
  font-size: 1.5rem;
  font-variant: small-caps;
`

const CookieConsentTextContent = styled.p`
  margin: 0.5em 0;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  a {
    color: #4caf50;
    text-decoration: underline;
  }

  a:hover {
    color: #45a049;
  }
`

const CookieConsentButtons = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  width: 75%;
  transform: translateX(25%);
`

const CookieConsentButton = styled.button`
  margin: 0 0.5em;
  padding: 0.5em 1em;
  border: none;
  cursor: pointer;
  font-size: 1em;
  background-color: ${props => (props.primary ? "#4caf50" : "#f44336")};
  color: white;

  &:hover {
    opacity: 0.8;
  }
`

const CookieConsentBanner = () => {
  const { showBanner, hideBanner, handleAccept, handleDecline, language } =
    useCookieConsent()

  const translations = useStaticTranslations({ language })
  const t = key => translations[key]

  if (!showBanner) {
    return null
  }

  return (
    <CookieConsentBannerWrapper className={hideBanner ? "fallDown" : ""}>
      <CookieConsentText>
        <CookieConsentTitle>{t("cookie.header")}</CookieConsentTitle>
        <CookieConsentTextContent>
          {t("cookie.message")}
          <Link to="/cookie-policy" language={language}>
            {t("cookie.link")}
          </Link>
          .
        </CookieConsentTextContent>
      </CookieConsentText>
      <CookieConsentButtons>
        <CookieConsentButton primary onClick={handleAccept}>
          {t("cookie.accept")}
        </CookieConsentButton>
        <CookieConsentButton onClick={handleDecline}>
          {t("cookie.decline")}
        </CookieConsentButton>
      </CookieConsentButtons>
    </CookieConsentBannerWrapper>
  )
}

export default CookieConsentBanner
